import React from 'react'
import Container from '../components/container'
import Layout from '../components/layout'

export default (props) => (
    <Layout location={props.location}>
        <Container>
            <h1>Donate</h1>
        </Container>
    </Layout>
)
